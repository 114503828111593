import React, { useEffect, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion';
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { AuthProvider } from "./contexts/LoginContext";
import { SidebarProvider } from './contexts/SidebarContext'
import { AppProvider } from './contexts/AppContext'
import { useAppState } from './hooks/useApp';
import useAuth from './hooks/useAuth';
import { handleNotification } from './components/Functions'

//Components
import Sidebar from './components/Sidebar'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTop'


//Guards
import AuthGuard from './guards/AuthGuard'

// Login
import Login from './pages/Login'

// Protected pages
import Home from './pages/protected/Home'
import Tutorial from './pages/protected/Tutorial'
import Schedule from './pages/protected/Schedule'
import Support from './pages/protected/Support'
import Networking from './pages/protected/Networking'
import Speakers from './pages/protected/Speakers'
import Sponsors from './pages/protected/Sponsors'
import Exhibitors from './pages/protected/Exhibitors'
import Ranking from './pages/protected/Ranking'
import GalleryPage from './pages/protected/Gallery'
import Notifications from './pages/protected/Notifications'
import Qrcode from './pages/protected/Qrcode'
import Links from './pages/protected/Links'
import Perfil from './pages/protected/Perfil'
import PointsOfInterest from './pages/protected/Points'

const LayoutProvider = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user, isInitialized, isAuthenticated } = useAuth()
    const { scrollContainerRef, socket } = useAppState()
    const notificationHandled = useRef()

    useEffect(() => {
        if (socket && isAuthenticated && !notificationHandled.current) {
            notificationHandled.current = true
            handleNotification(socket, 'routes')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, isAuthenticated]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.has('addCode')) {
            const addCodeValue = params.get('addCode');
            params.delete('addCode');  // Remove the addCode parameter from the search params
            const newSearch = params.toString();
            const newUrl = newSearch ? `?${newSearch}` : '';
            navigate(`/qrcode${newUrl}`, { state: { item: addCodeValue } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isInitialized) {
        return children
    }

    return (
        <>
            {!isAuthenticated || location.pathname === '/login' ? (
                <motion.div key={'login'} className="InitializedLayoutWrapper h-100 overflow-hidden" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                    <div className="main">
                        {children}
                    </div>
                </motion.div>
            ) : (
                <AnimatePresence mode="wait" initial={false}>
                    {user && user.status === 0 ? (
                        <motion.div key={'tutorial'} className="h-100" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                            <Tutorial />
                        </motion.div>
                    ) : (
                        <motion.div key={'main'} className="h-100" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                            <Navbar />
                            <div className="AuthenticatedLayoutWrapper">
                                <div className="main" key={'mainPage'} ref={scrollContainerRef}>
                                    <AnimatePresence mode="wait" initial={false}>
                                        {children}
                                    </AnimatePresence>
                                </div>
                                <Sidebar />
                            </div>
                            <Footer />
                        </motion.div>
                    )}
                </AnimatePresence>
            )}
        </>
    )
};

const RouteManager = () => {
    const location = useLocation();

    return (
        <motion.div className="h-100 overflow-hidden" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <AuthProvider>
                <SidebarProvider>
                    <AppProvider>
                        <ScrollToTop />
                        <LayoutProvider>
                            <Routes location={location} key={location.pathname}>
                                <Route path="*" element={<AuthGuard><Home /></AuthGuard>} />
                                <Route path="/" element={<AuthGuard><Home /></AuthGuard>} />
                                <Route path="/login" element={<AuthGuard><Login /></AuthGuard>} />
                                <Route path="/agenda" element={<AuthGuard><Schedule /></AuthGuard>} />
                                <Route path="/agenda/:id" element={<AuthGuard><Schedule /></AuthGuard>} />
                                {/* <Route path="/feed" element={<AuthGuard><Feed /></AuthGuard>} /> */}
                                <Route path="/contatos" element={<AuthGuard><Networking /></AuthGuard>} />
                                <Route path="/contatos/:id" element={<AuthGuard><Networking /></AuthGuard>} />
                                <Route path="/palestrantes" element={<AuthGuard><Speakers /></AuthGuard>} />
                                <Route path="/palestrantes/:id" element={<AuthGuard><Speakers /></AuthGuard>} />
                                <Route path="/patrocinadores" element={<AuthGuard><Sponsors /></AuthGuard>} />
                                <Route path="/patrocinadores/:id" element={<AuthGuard><Sponsors /></AuthGuard>} />
                                <Route path="/expositores" element={<AuthGuard><Exhibitors /></AuthGuard>} />
                                <Route path="/expositores/:id" element={<AuthGuard><Exhibitors /></AuthGuard>} />
                                <Route path="/gamificacao" element={<AuthGuard><Ranking /></AuthGuard>} />
                                <Route path="/galeria" element={<AuthGuard><GalleryPage /></AuthGuard>} />
                                <Route path="/qrcode" element={<AuthGuard><Qrcode /></AuthGuard>} />
                                <Route path="/perfil" element={<AuthGuard><Perfil /></AuthGuard>} />
                                <Route path="/suporte/:id" element={<AuthGuard><Support /></AuthGuard>} />
                                <Route path="/links" element={<AuthGuard><Links /></AuthGuard>} />
                                <Route path="/notificacoes" element={<AuthGuard><Notifications /></AuthGuard>} />
                                <Route path="/notificacoes/:id" element={<AuthGuard><Notifications /></AuthGuard>} />
                                <Route path="/pontos-de-interesse" element={<AuthGuard><PointsOfInterest /></AuthGuard>} />
                            </Routes>
                        </LayoutProvider>
                    </AppProvider>
                </SidebarProvider>
            </AuthProvider >
        </motion.div>
    )
}

export default RouteManager;
