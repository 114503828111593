import { useState, useEffect } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { useAppState } from '../../hooks/useApp'

const Support = () => {
    const [pageData, setPageData] = useState()
    const { socket } = useAppState()
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (socket) {
            socket.on('pageDataSupport', (response) => {
                setPageData(response);
            });

            socket.emit('pageDataSupport', { page: 'support' });

            // Cleanup listener when component unmounts
            return () => {
                socket.off('pageDataSupport');
            };
        }
    }, [socket]);

    return (
        <motion.div className="container speakerContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <header className="pageHeader">
                <Button type="button" className="backButton" onClick={(() => navigate((`/${location.search}`)))}>
                    <FontAwesomeIcon icon={faAngleLeft} size={"lg"} />
                </Button>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ms-2">
                        <li className="breadcrumb-item"><h5 onClick={(() => navigate((`/palestrantes${location.search}`)))}>Ajuda & Suporte</h5></li>
                    </ol>
                </nav>
            </header>
            <Row className="mb-3">
                <Col className="text-center mt-4" sm={12}>
                    <p className="opacity-75">Página em construção...</p>
                </Col>
            </Row>
        </motion.div>
    )
}

export default Support