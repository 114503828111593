import React from 'react'
import { motion, LayoutGroup, } from 'framer-motion'
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHouse,
    faCalendarDays,
    faUserGroup,
    faBars,
    faQrcode
} from "@fortawesome/free-solid-svg-icons";
import useSidebar from "../hooks/useSidebar";
import { useDebounce } from '../components/RouteDebouncer.js';

const Sections = [
    {
        href: "/",
        icon: faHouse,
        title: "Home"
    },
    {
        href: "/agenda",
        icon: faCalendarDays,
        title: "Agenda"
    },
    {
        href: "/qrcode",
        icon: faQrcode,
        title: "QR Code"
    },
    {
        href: "/contatos",
        icon: faUserGroup,
        title: "Networking",
    },
    {
        href: "/menu",
        icon: faBars,
        title: "Menu"
    }
];

const Footer = () => {

    return (
        <footer className="footer">
            <ul className="nav nav-pills nav-fill">
                <LayoutGroup id="a">
                    {Sections.map((item, i) => (
                        <FooterNavItem key={'navItem' + i} item={item} />
                    ))}
                </LayoutGroup>
            </ul>
        </footer>
    )
}

const FooterNavItem = ({ item }) => {
    const { isOpen, setIsOpen } = useSidebar();
    const { pathname } = useLocation()
    const navigate = useNavigate();
    const location = useLocation();
    const debouncedNavigate = useDebounce((path, props = undefined) => {
        navigate({
            pathname: path,
            search: location.search,
            state: props,
        });
    }, 250);
    
    const handleSidebar = () => {
        if (isOpen) {
            if (window.innerWidth <= 769) {
                setIsOpen(!isOpen);
            }
        }
    };

    if (item.href === "/" || item.href === "/qrcode" || item.href === "/feed" || item.href === "/agenda" || item.href === "/contatos") {
        return (
            <motion.li className="nav-item" onClick={() => {
                debouncedNavigate(item.href)
                handleSidebar()
            }}>
                <span className={item.href === pathname ? 'active' : ''}>
                    <FontAwesomeIcon icon={item.icon} size="lg" />
                    {(pathname === item.href) && <motion.div
                        className="underline"
                        layoutId="underline"
                    />}
                </span>
            </motion.li>
        )
    } else {
        return (
            <motion.li className="nav-item" onClick={() => {
                setIsOpen((prevState) => !prevState);
            }}>
                <span className={(pathname !== "/" && pathname !== "/qrcode" && pathname !== "/feed" && pathname !== "/agenda" && pathname !== "/contatos") ? 'active' : ''}>
                    <FontAwesomeIcon icon={item.icon} size="lg" />
                    {(pathname !== "/" && pathname !== "/qrcode" && pathname !== "/feed" && pathname !== "/agenda" && pathname !== "/contatos") && <motion.div
                        className="underline"
                        layoutId="underline"
                    />}
                </span>
            </motion.li>
        )
    }
}

export default Footer