import { createContext, useReducer, useContext, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion"
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import NotyfContext from "../contexts/NotyfContext";
import { useGlobalState } from '../hooks/useInitialization.js';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
};

const LoginReducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE": {
      return {
        ...state,
        isInitialized: true,
      }
    }
    case "SIGN_IN":
      try {
        action.payload.json = JSON.parse(action.payload.json)
      } catch (e) {
        action.payload.json = {}
      }
      return {
        ...state,
        user: action.payload,
        isInitialized: true,
        isAuthenticated: true,
      };
    case "SIGN_OUT":
      return {
        isInitialized: true,
        isAuthenticated: false,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        }
      };
    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [userState, dispatch] = useReducer(LoginReducer, initialState);
  const { dataApplication } = useGlobalState();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  const decoded = searchParams.get('encode')

  const handleEnded = async () => {
    if (dataApplication.user) {
      dispatch({ type: "SIGN_IN", payload: dataApplication.user });
    } else if (decoded) {
      let id
      if (base64regex.test(decoded)) {
        let decode64 = window.atob(decoded)
        id = decode64.split("=")
      }
      try {
        await signIn(id[0], id[1])
      } catch (error) {
        dispatch({ type: "INITIALIZE" });
      }
    } else {
      dispatch({ type: "INITIALIZE" });
    }
  }

  const signIn = async (email, access_code) => {
    try {
      const response = await axios.post(`/api/login`, {
        event_id: dataApplication.event_id,
        email: email,
        access_code: access_code,
        token: dataApplication.token
      }, {
        withCredentials: true // This tells Axios to send the cookie along with the request
      });
      dispatch({ type: "SIGN_IN", payload: response.data.message });
      navigate(`/${location.search}`)
    } catch (error) {
      if (error.response) {
        console.log(error.response)
        if (error.response.data["message"] === 1) {
          error.message = "Email não cadastrado para evento"
          throw error.message
        } else if (error.response.data["message"] === 2) {
          error.message = "Código de acesso inválido"
          throw error.message
        } else {
          throw error.response.data["message"];
        }
      } else {
        throw error.message;
      }
    }
  };

  const signOut = () => {
    dispatch({ type: "SIGN_OUT" });
    navigate(`/login${location.search}`)
  };

  const Loader = () => {
    return (
      <motion.div className="loginLoadingContainer" key={'loginLoadingContainer'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
        <div>
          {dataApplication.customizacao.login.type === 'video' ?
            <video className="logo" onEnded={handleEnded} autoPlay loop={false} muted playsInline src={`${dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + dataApplication.customizacao.login.url}`}></video>
            :
            <img src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + dataApplication.customizacao.login.url} alt="Logo" className="logo"></img>}
        </div>
      </motion.div>
    )
  }

  useEffect(() => {
    if (dataApplication.customizacao.login.type !== 'video') {
      setTimeout(() => {
        handleEnded()
      }, dataApplication.customizacao.login.loaderTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...userState,
        dispatch,
        signIn,
        signOut,
      }}
    >
      <AnimatePresence mode={'wait'} initial={true}>
        {userState.isInitialized === true ?
          (children) : (<Loader key={'loginLoader'} />)}
      </AnimatePresence>
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };