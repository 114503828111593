import { useState, useEffect, useRef } from 'react'
import { Button, Col, Badge, Row, InputGroup } from 'react-bootstrap'
import { AnimatePresence, motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faLocationDot, faClock, faCalendar, faFile, faClose, faDiceD6, faPhotoFilm, faCalendarCheck } from '@fortawesome/free-solid-svg-icons'
import {  faCalendar as faCalendarRegular } from '@fortawesome/free-regular-svg-icons'
import { format, isWithinInterval, isBefore, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useGlobalState } from '../../hooks/useInitialization.js';
import { useAppState } from '../../hooks/useApp.js'
import PageLoader from '../../components/PageLoader.js'
import { SpeakerCard } from './Speakers'
import { TextWithLineBreaks, cleanUpSpecialChars, convertDate } from "../../components/Functions.js"

const weekdayMap = {
    'segunda-feira': 'SEG',
    'terça-feira': 'TER',
    'quarta-feira': 'QUA',
    'quinta-feira': 'QUI',
    'sexta-feira': 'SEX',
    'sábado': 'SAB',
    'domingo': 'DOM'
};

const formatDateObject = (dateString) => {
    const date = new Date(dateString);
    const fullDayName = format(date, 'EEEE', { locale: ptBR });
    return {
        dayFormat: convertDate(date, 'dd/MM'),
        string: weekdayMap[fullDayName] || fullDayName
    };
};

const Agenda = () => {
    const { socket } = useAppState()
    const [pageData, setPageData] = useState()
    const [pageDataComplete, setPageDataComplete] = useState()
    const [searchQuery, setSearchQuery] = useState('');
    const [dateFilter, setDateFilter] = useState()
    const tabKeyRef = useRef('events')
    const [tabKey, setTabKey] = useState('events');
    const location = useLocation();
    const { id } = useParams();
    const { item } = location.state || {};
    const [pageDataItem, setPageDataItem] = useState(item ? item : undefined)
    const tabSwitching = useRef(false)

    const search = (data) => {
        if (searchQuery.trim() !== '') {
            const lowercaseQuery = cleanUpSpecialChars(searchQuery.toLowerCase());
            let newData = data.filter(props => {
                const searchableFields = [
                    cleanUpSpecialChars(props.json.title.toLowerCase()),
                    cleanUpSpecialChars(props.json.location ? props.json.location.toLowerCase() : '')
                ];
                props.speakers.forEach(speaker => {
                    searchableFields.push(cleanUpSpecialChars(speaker.json.name.toLowerCase()));
                    if (speaker.json.role) {
                        searchableFields.push(cleanUpSpecialChars(speaker.json.role.toLowerCase()));
                    }
                });
                return searchableFields.some(field => field.includes(lowercaseQuery));
            });
            return newData
        } else {
            return data
        }
    }

    const handleTabSwitch = (tab) => {
        if (tabKeyRef.current === tab) {
            return
        }
        if (tabSwitching.current === false) {
            setTabKey(tab);
            setPageData()
            setPageDataComplete()
        }
    }

    useEffect(() => {
        if (dateFilter && pageData) {
            let newData = search(pageDataComplete)
            if (searchQuery.trim() === '' && tabKey === 'events') {
                // Further filter by date if dateFilter is set
                if (dateFilter.date) {
                    let newDataByDate = newData.filter(category => {
                        if (category.start_date) {
                            let date = new Date(category.start_date);
                            date = format(date, 'dd/MM')
                            return date === dateFilter.date; // Only include categories that match the specified day
                        }
                        return ""
                    });
                    newData = newDataByDate
                }
            }
            if (tabKey === 'favorites') {
                newData = newData.filter(event => event.interest?.confirmed === 1 || event.interest?.interested === 1);
            }
            setPageData(prevState => ({
                ...prevState,
                schedule: newData
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, dateFilter]);

    useEffect(() => {
        if (socket && !pageDataItem) {

            tabSwitching.current = true
            tabKeyRef.current = tabKey

            socket.on('pageDataSchedule', (response) => {
                if (id && response.id) {
                    setPageDataItem(response);
                } else {
                    const uniqueDatesSet = new Set(response.map(event => {
                        const date = new Date(event.start_date);
                        date.setHours(0, 0, 0, 0);
                        return date.toISOString();
                    }));
                    const uniqueDatesArray = Array.from(uniqueDatesSet);
                    const formattedDates = uniqueDatesArray.map(date => formatDateObject(date));
                    let dateFormat
                    let dateIndex
                    const currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0);
                    const currentDateFormat = formatDateObject(currentDate.toISOString()).dayFormat;
                    const currentIndex = formattedDates.findIndex(date => date.dayFormat === currentDateFormat);
                    if (currentIndex !== -1) {
                        dateIndex = currentIndex
                        dateFormat = currentDateFormat
                    } else if (!dateFilter) {
                        dateIndex = 0
                        dateFormat = formattedDates[0].dayFormat;
                    } else {
                        dateIndex = dateFilter.index
                        dateFormat = dateFilter.date;
                    }
                    setTimeout(() => {
                        setPageDataComplete(response);
                        let searchedData = search(response)
                        if (tabKey === 'favorites') {
                            searchedData = searchedData.filter(event => event.interest?.confirmed === 1 || event.interest?.interested === 1);
                        } else {
                            if (searchQuery.trim() === '' && tabKey === 'events') {
                                searchedData = searchedData.filter(category => {
                                    if (category.start_date) {
                                        let date = new Date(category.start_date);
                                        date = format(date, 'dd/MM')
                                        return date === dateFormat
                                    }
                                    return ""
                                });
                            }
                        }
                        setPageData({ 'schedule': searchedData, 'dates': formattedDates });
                        if (!dateFilter) {
                            setDateFilter({ 'index': dateIndex, 'date': formattedDates[0].dayFormat });
                        }
                        tabSwitching.current = false
                    }, 250);
                }
            });
            socket.emit('pageDataSchedule', { page: 'schedule', id: id });
            // Cleanup listener when component unmounts
            return () => {
                socket.off('pageDataSchedule');
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, id, tabKey, pageDataItem]);

    console.log('ayuuuuuuuuuuuuuu')

    return (
        <motion.div className="container eventContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <AnimatePresence mode="wait" initial={false}>
                {id > 0 ? (
                    <motion.div key={'itemA'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                        <EventDetails pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} pageDataItem={pageDataItem} setPageDataItem={setPageDataItem} handleInterest={handleInterest} tabKey={tabKey} ></EventDetails>
                    </motion.div>
                ) : (
                    <motion.div key={'listB'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                        <div className="header-wrapper my-3">
                            <div className="tab-wrapper">
                                <motion.div
                                    className="switch-background"
                                    initial={false}
                                    animate={{ x: tabKey === 'events' ? 0 : '100%' }}
                                    transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                                />
                                <button
                                    className={`tab-button ${tabKey === 'events' ? 'active' : ''}`}
                                    onClick={() => handleTabSwitch('events')}
                                >
                                    Eventos
                                </button>
                                <button
                                    className={`tab-button ${tabKey === 'favorites' ? 'active' : ''}`}
                                    onClick={() => handleTabSwitch('favorites')}
                                >
                                    Destacados
                                </button>
                            </div>
                        </div>
                        <InputGroup className="mb-3">
                            <motion.input
                                layout
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1, transition: { duration: 0.5 } }}
                                exit={{ opacity: 0 }}
                                className="form-control"
                                placeholder="Pesquisar..."
                                aria-label="search"
                                aria-describedby="search"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                            />
                            <AnimatePresence>
                                {searchQuery !== '' && (
                                    <motion.div
                                        layout
                                        id="search"
                                        className="input-group-text"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1, transition: { duration: 0.5 } }}
                                        exit={{ opacity: 0 }}
                                    >
                                        <FontAwesomeIcon className="primaryColorT" icon={faClose} onClick={() => setSearchQuery('')} />
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </InputGroup>
                        <AnimatePresence mode="wait" initial={false}>
                            {tabKey === 'events' ? (
                                <AllEvents key={'itemC'} pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} tabKey={tabKey} searchQuery={searchQuery} dateFilter={dateFilter} setDateFilter={setDateFilter} pageDataItem={pageDataItem} setPageDataItem={setPageDataItem} handleInterest={handleInterest} />
                            ) : (
                                <FavoriteEvents key={'itemD'} pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} tabKey={tabKey} pageDataItem={pageDataItem} setPageDataItem={setPageDataItem} handleInterest={handleInterest} />
                            )}
                        </AnimatePresence>
                    </motion.div>
                )
                }
            </AnimatePresence >
        </motion.div >
    )
}

export const AllEvents = ({ pageData, setPageData, pageDataComplete, setPageDataComplete, tabKey, searchQuery, dateFilter, setDateFilter, pageDataItem, setPageDataItem, handleInterest }) => {
    const { dataApplication } = useGlobalState();

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            {(!pageData) ? (
                <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                    <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                </motion.div>
            ) : (
                <>
                    {searchQuery.trim() === '' && <header className="pageHeaderDays mb-3 menuScroll">
                        {pageData && (Array.isArray(pageData.dates)) && pageData.dates.map((item, index) => (
                            <div key={'menuItem' + index}>
                                <div className={dateFilter.index === index ? 'card selectedDate pointer mb-2' : 'card pointer mb-2'} onClick={() => setDateFilter({ 'index': index, 'date': item.dayFormat })}>
                                    <div className="card-body text-center">
                                        <p className="mb-0">{item.dayFormat}</p>
                                        <h5 className="mb-0">{item.string}</h5>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </header>}
                    {pageData && (Array.isArray(pageData.schedule)) && pageData.schedule.length > 0 ? (
                        <Row>
                            {pageData.schedule.map((item, index) => (
                                <Col key={'EventCard' + index} md={6} className="event-card mx-0 pointer">
                                    <EventCard item={item} pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} pageDataItem={pageDataItem} setPageDataItem={setPageDataItem} handleInterest={handleInterest} tabKey={tabKey} layout={searchQuery.trim() === '' ? 0 : 1} origin={'schedule'} />
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Row className="mb-3">
                            <Col className="text-center mt-4" sm={12}>
                                <p className="opacity-75">Nenhum evento encontrado</p>
                            </Col>
                        </Row>
                    )}
                </>
            )
            }
        </motion.div>
    )
}

export const FavoriteEvents = ({ pageData, setPageData, pageDataComplete, setPageDataComplete, tabKey, pageDataItem, setPageDataItem, handleInterest }) => {
    const { dataApplication } = useGlobalState();

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            {(!pageData) ? (
                <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                    <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                </motion.div>
            ) : (
                <>
                    {pageData && (Array.isArray(pageData.schedule)) && pageData.schedule.length > 0 ? (
                        <Row>
                            {pageData.schedule.map((item, index) => (
                                <Col key={'EventCardFavorite' + index} md={6} className="mx-0 pointer">
                                    <EventCard item={item} pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} pageDataItem={pageDataItem} setPageDataItem={setPageDataItem} handleInterest={handleInterest} tabKey={tabKey} layout={1} origin={'schedule'} />
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Row className="mb-3">
                            <Col className="text-center mt-4" sm={12}>
                                <p className="opacity-75">Você não destacou nenhum evento</p>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </motion.div>
    )
}

export const EventCard = ({ item, pageData, setPageData, pageDataComplete, setPageDataComplete, pageDataItem, setPageDataItem, handleInterest, tabKey, layout = undefined, origin }) => {
    const { dataApplication } = useGlobalState();
    const { dispatch } = useAppState()
    const { socket } = useAppState()
    const navigate = useNavigate();
    const location = useLocation();

    const handleCardClick = (item) => {
        if (origin === 'schedule') {
            navigate(`/agenda/${item.id}${location.search}`, { state: { item: item } });
        } else {
            navigate(`/agenda/${item.id}${location.search}`);
        }
        dispatch({ type: 'updatePageDataItem' });
    }

    if (item && item.json) {
        switch (layout) {
            case 1:
                return (
                    <div className="event-card card pointer p-0 mb-3" onClick={() => handleCardClick(item)}>
                        <div className="card-body d-block pb-2">
                            <div>
                                <p className="fw-bold mb-0 d-flex justify-content-between">
                                    <EventStatusBadge item={item} layout={layout}></EventStatusBadge>
                                    <span>
                                        {convertDate(item.start_date, 'HH:mm') + ' - ' + convertDate(item.end_date, 'HH:mm')}
                                    </span>
                                </p>
                                <hr className="mb-2 mt-1"></hr>
                                <h6 className="card-title mb-1">
                                    {item.json.title}
                                </h6>
                                {item.json.location && <div className="mt-1">
                                    <small><FontAwesomeIcon icon={faLocationDot} className="me-1" size={"sm"} />{item.json.location}</small>
                                </div>}
                                {item.speakers && item.speakers.length > 0 && <div className="d-flex speakers py-2">
                                    {item.speakers.map((element, sIndex) => (
                                        <img key={'speakersImg' + sIndex} src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + element.json.image} alt="speakerPicture" />
                                    ))}
                                </div>}
                                <div>
                                    <FontAwesomeIcon className={`iconsHover me-2 ${item.interest && item.interest.interested > 0 ? 'primaryColorT' : ''}`} size={'sm'} icon={item.interest && item.interest.interested > 0 ? faCalendarCheck : faCalendarRegular} onClick={(e) => handleInterest(e, 'interested', item.interest, item.id, socket, pageData, setPageData, pageDataComplete, setPageDataComplete, pageDataItem, setPageDataItem, tabKey)} />
                                    <span className="small me-4"><b>{item.interest ? item.interest.interested_count : 0}</b> interessados</span>
                                    <FontAwesomeIcon className={`iconsHover me-2 ${item.interest && item.interest.confirmed > 0 ? 'primaryColorT' : ''}`} size={'sm'} icon={item.interest && item.interest.confirmed > 0 ? faCalendarCheck : faCalendarRegular} onClick={(e) => handleInterest(e, 'confirmed', item.interest, item.id, socket, pageData, setPageData, pageDataComplete, setPageDataComplete, pageDataItem, setPageDataItem, tabKey)} />
                                    <span className="small"><b>{item.interest ? item.interest.confirmed_count : 0}</b> confirmados</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )

            default:

                return (
                    <div className="event-card card pointer p-0 mb-3" onClick={() => handleCardClick(item)}>
                        <div className="card-body pb-2">
                            <div className="vertically-centered">
                                <h6 className="mb-1">{convertDate(item.start_date, 'HH:mm')}</h6>
                                <h6 className="mb-0">{convertDate(item.end_date, 'HH:mm')}</h6>
                            </div>
                            <div className="dateBorder"></div>
                            <div>
                                <h6 className="card-title mb-1">
                                    {item.json.title}
                                </h6>
                                <EventStatusBadge item={item} layout={layout}></EventStatusBadge>
                                {item.json.location && <div className="mt-1">
                                    <small><FontAwesomeIcon icon={faLocationDot} className="me-1" size={"sm"} />{item.json.location}</small>
                                </div>}
                                {item.speakers && item.speakers.length > 0 && <div className="d-flex speakers py-2">
                                    {item.speakers.map((element, sIndex) => (
                                        <img key={'speakersImg' + sIndex} src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + element.json.image} alt="speakerPicture" />
                                    ))}
                                </div>}
                                <div>
                                    <FontAwesomeIcon className={`iconsHover me-2 ${item.interest && item.interest.interested > 0 ? 'primaryColorT' : ''}`} size={'sm'} icon={item.interest && item.interest.interested > 0 ? faCalendarCheck : faCalendarRegular} onClick={(e) => handleInterest(e, 'interested', item.interest, item.id, socket, pageData, setPageData, pageDataComplete, setPageDataComplete, pageDataItem, setPageDataItem, tabKey)} />
                                    <span className="small me-4"><b>{item.interest ? item.interest.interested_count : 0}</b> interessados</span>
                                    <FontAwesomeIcon className={`iconsHover me-2 ${item.interest && item.interest.confirmed > 0 ? 'primaryColorT' : ''}`} size={'sm'} icon={item.interest && item.interest.confirmed > 0 ? faCalendarCheck : faCalendarRegular} onClick={(e) => handleInterest(e, 'confirmed', item.interest, item.id, socket, pageData, setPageData, pageDataComplete, setPageDataComplete, pageDataItem, setPageDataItem, tabKey)} />
                                    <span className="small"><b>{item.interest ? item.interest.confirmed_count : 0}</b> confirmados</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
        }
    }
}

const isCurrentDateBetween = (start, end) => {
    const now = new Date();
    if (!start || !end) {
        return false;
    }
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (isNaN(startDate) || isNaN(endDate)) {
        return false;
    }
    try {
        const result = isWithinInterval(now, { start: startDate, end: endDate });
        return result;
    } catch (error) {
        return false;
    }
};

const EventStatusBadge = ({ item, layout }) => {
    const now = new Date();
    const endDate = parseISO(item.end_date);
    if (isCurrentDateBetween(item.start_date, item.end_date)) {
        return (
            <Badge bg="primary">Em andamento</Badge>
        )
    } else if (isBefore(endDate, now)) {
        return (
            <Badge bg="secondary">Finalizado</Badge>
        )
    } else {
        if (layout === 1) {
            return (
                <span>{convertDate(item.start_date, 'dd/MM')}</span>
            )
        } else {
            return
        }
    }
}

export const EventDetails = ({ pageData, setPageData, pageDataComplete, setPageDataComplete, pageDataItem, setPageDataItem, handleInterest, tabKey }) => {
    const { dataApplication } = useGlobalState()
    const { socket } = useAppState()
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <header className="pageHeader mb-3">
                <Button type="button" className="backButton" onClick={(() => navigate(`/agenda${location.search}`))}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </Button>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ms-2">
                        <li className="breadcrumb-item"><h5 onClick={(() => navigate(`/agenda${location.search}`))}>Agenda</h5></li>
                        <li className="breadcrumb-item"><h5>Detalhes</h5></li>
                    </ol>
                </nav>
            </header>
            {(!pageDataItem) ? (
                <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                    <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                </motion.div>
            ) : (
                <>
                    <div className="eventItemTitle">
                        <h6 className='mb-0 primaryColorText'>{pageDataItem.json.title}</h6>
                    </div>
                    <div className="eventItemDetails">
                        <div className="eventItemCalendar">
                            <FontAwesomeIcon icon={faCalendar} />
                            <span>{convertDate(pageDataItem.start_date, 'dd')}</span>
                        </div>
                        <div>
                            <p className="mb-1 ms-3"><FontAwesomeIcon icon={faLocationDot} className="me-1" />{pageDataItem.json.location}
                            </p>
                            <p className="mb-0 ms-3"><FontAwesomeIcon icon={faClock} className="me-1" />{convertDate(pageDataItem.start_date, 'HH:mm') + ' - ' + convertDate(pageDataItem.end_date, 'HH:mm')}</p>
                        </div>
                    </div>
                    <Row className="mx-0 my-3">
                        <hr className="mb-0"></hr>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <h6 className="fw-bold">Status</h6>
                            <div>
                                <FontAwesomeIcon className={`iconsHover me-2 ${pageDataItem.interest && pageDataItem.interest.interested > 0 ? 'primaryColorT' : ''}`} size={'sm'} icon={pageDataItem.interest && pageDataItem.interest.interested > 0 ? faCalendarCheck : faCalendarRegular} onClick={(e) => handleInterest(e, 'interested', pageDataItem.interest, pageDataItem.id, socket, pageData, setPageData, pageDataComplete, setPageDataComplete, pageDataItem, setPageDataItem, tabKey)} />
                                <span className="small me-4"><b>{pageDataItem.interest ? pageDataItem.interest.interested_count : 0}</b> interessados</span>
                                <FontAwesomeIcon className={`iconsHover me-2 ${pageDataItem.interest && pageDataItem.interest.confirmed > 0 ? 'primaryColorT' : ''}`} size={'sm'} icon={pageDataItem.interest && pageDataItem.interest.confirmed > 0 ? faCalendarCheck : faCalendarRegular} onClick={(e) => handleInterest(e, 'confirmed', pageDataItem.interest, pageDataItem.id, socket, pageData, setPageData, pageDataComplete, setPageDataComplete, pageDataItem, setPageDataItem, tabKey)} />
                                <span className="small"><b>{pageDataItem.interest ? pageDataItem.interest.confirmed_count : 0}</b> confirmados</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="mt-3">
                            <h6 className="fw-bold">Informações</h6>
                            <small><TextWithLineBreaks text={pageDataItem.json.about}></TextWithLineBreaks></small>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="mt-3">
                            <h6 className="fw-bold">Palestrantes</h6>
                            {(Array.isArray(pageDataItem.speakers)) && pageDataItem.speakers.length > 0 ? (
                                <Row>
                                    {pageDataItem.speakers.map((item, index) => (
                                        <Col key={'SpeakerCard' + index} size={6} md={4} sm={6} lg={3} xs={6}>
                                            <SpeakerCard item={item} pageDataItem={pageDataItem} setPageDataItem={setPageDataItem} origin={'schedule'} />
                                        </Col>
                                    ))}
                                </Row>
                            ) : (
                                <Row className="mb-3">
                                    <Col className="text-center mt-4" sm={12}>
                                        <p className="opacity-75">Nenhum palestrante encontrado</p>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="mt-3">
                            <h6 className="fw-bold">Atividades</h6>
                            {pageDataItem.activities && Object.keys(pageDataItem.activities).length === 0 ? (
                                ""
                            ) : (
                                <Row className="mb-3">
                                    <Col className="text-center mt-4" md={12}>
                                        <FontAwesomeIcon icon={faDiceD6} className="me-1 fileIcon" />
                                    </Col>
                                    <Col className="text-center mt-4" sm={12}>
                                        <p className="opacity-75">Nenhuma atividade encontrada</p>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="mt-3">
                            <h6 className="fw-bold">Sessão ao vivo</h6>
                            {pageDataItem.liveSession && Object.keys(pageDataItem.liveSession).length === 0 ? (
                                ""
                            ) : (
                                <Row className="mb-3">
                                    <Col className="text-center mt-2" md={12}>
                                        <video src="" className="videoFallback" muted></video>
                                    </Col>
                                    <Col className="text-center mt-4" sm={12}>
                                        <p className="opacity-75">Nenhuma transmissão disponível</p>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="mt-3">
                            <h6 className="fw-bold">Mídia</h6>
                            {pageDataItem.media && Object.keys(pageDataItem.media).length === 0 ? (
                                ""
                            ) : (
                                <Row className="mb-3">
                                    <Col className="text-center mt-4" md={12}>
                                        <FontAwesomeIcon icon={faPhotoFilm} className="me-1 fileIcon" />
                                    </Col>
                                    <Col className="text-center mt-4" sm={12}>
                                        <p className="opacity-75">Nenhuma mídia encontrada</p>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className="mt-3">
                            <h6 className="fw-bold">Anexos</h6>
                            {pageDataItem.files ? (
                                ""
                            ) : (
                                <Row className="mb-3">
                                    <Col className="text-center mt-4" md={12}>
                                        <FontAwesomeIcon icon={faFile} className="me-1 fileIcon" />
                                    </Col>
                                    <Col className="text-center mt-4" sm={12}>
                                        <p className="opacity-75">Nenhuma anexo encontrado</p>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export const handleInterest = (e, type, item, id, socket, pageData, setPageData, pageDataComplete, setPageDataComplete, pageDataItem = undefined, setPageDataItem = undefined, tabKey = undefined) => {
    e.stopPropagation();
    let props = { 'interested': (item && item.interested ? item.interested : 0), 'confirmed': (item && item.confirmed ? item.confirmed : 0), 'interested_count': (item && item.interested_count ? item.interested_count : 0), 'confirmed_count': (item && item.confirmed_count ? item.confirmed_count : 0) }
    if (type === 'interested') {
        props.interested = 1 - props.interested
        if (props.interested === 1) {
            props.interested_count = parseInt(props.interested_count) + 1
        } else {
            props.interested_count = Math.max(0, parseInt(props.interested_count) - 1);
        }
    } else {
        props.confirmed = 1 - props.confirmed
        if (props.confirmed === 1) {
            props.confirmed_count = parseInt(props.confirmed_count) + 1
        } else {
            props.confirmed_count = Math.max(0, parseInt(props.confirmed_count) - 1);
        }
    }

    if (pageDataItem) {
        //if element is open
        if (pageDataItem.schedule) {
            //For external pages
            const updatedEvents = pageDataItem.schedule.map(event => {
                if (event.id === id) {
                    return {
                        ...event,
                        interest: {
                            ...event.interest,
                            interested: props.interested,
                            confirmed: props.confirmed,
                            interested_count: props.interested_count,
                            confirmed_count: props.confirmed_count,
                        }
                    };
                }
                return event;
            });
            setPageDataItem(prevPageData => ({
                ...prevPageData,
                schedule: updatedEvents
            }));
        } else {
            //Within the pages
            setPageDataItem(prevState => ({
                ...prevState,
                interest: {
                    ...prevState.interest,
                    interested: props.interested,
                    confirmed: props.confirmed,
                    interested_count: props.interested_count,
                    confirmed_count: props.confirmed_count,
                }
            }));
        }
    }
    if (pageData && pageData.schedule) {
        //For external pages and within the pages
        const updatedEvents = pageData.schedule.map(event => {
            if (event.id === id) {
                return {
                    ...event,
                    interest: {
                        ...event.interest,
                        interested: props.interested,
                        confirmed: props.confirmed,
                        interested_count: props.interested_count,
                        confirmed_count: props.confirmed_count,
                    }
                };
            }
            return event;
        }).filter(element => {
            if (tabKey === 'destacados') {
                return element.interest.interested !== 0 || element.interest.confirmed !== 0;
            }
            return element
        });
        setPageData(prevPageData => ({
            ...prevPageData,
            schedule: updatedEvents
        }));
        if (pageDataComplete) {
            const updatedEvents2 = pageDataComplete.map(event => {
                if (event.id === id) {
                    return {
                        ...event,
                        interest: {
                            ...event.interest,
                            interested: props.interested,
                            confirmed: props.confirmed,
                            interested_count: props.interested_count,
                            confirmed_count: props.confirmed_count,
                        }
                    };
                }
                return event;
            }).filter(element => {
                if (tabKey === 'destacados') {
                    return element.interest.interested !== 0 || element.interest.confirmed !== 0;
                }
                return element
            });
            setPageDataComplete(updatedEvents2);
        }
    }
    socket.emit('eventInterest', { item: props, 'id': id });
};

export default Agenda