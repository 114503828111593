/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Collapse } from 'react-bootstrap'
import { motion } from 'framer-motion'
import { NavLink, useNavigate } from "react-router-dom";
import useOuterClick from "../hooks/useOuterClick";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useLocation } from "react-router-dom";
import useSidebar from "../hooks/useSidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebounce } from '../components/RouteDebouncer.js';
import { useGlobalState } from "../hooks/useInitialization";
import {
    faFacebook,
    faWhatsapp,
    faInstagram,
    faLinkedin,
    faYoutube,
    faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { faStore, faMapLocation, faHome, faHouse, faCalendarDays, faUserGroup, faChevronDown, faCrown, faRankingStar, faImages, faCalendar, faChalkboardUser, faUser, faUsers, faBell, faCog, faEnvelope, faChartLine, faBook, faCamera, faCogs, faFile, faFolder, faGlobe, faHeart, faImage, faMap, faPaperclip, faPhone, faPlane, faShoppingCart, faSignInAlt, faSignOutAlt, faStar, faTags, faThumbsUp, faTools, faTrophy, faWrench, faBookmark, faBriefcase, faBullhorn, faChartPie, faClipboard, faComment, faCompass, faCopy, faCreditCard, faDatabase, faDollarSign, faDownload, faExclamationCircle, faExternalLinkAlt, faFileAlt, faFileArchive, faFileAudio, faFileCode, faFileExcel, faFileImage, faFilePdf, faFilePowerpoint, faFileVideo, faFileWord, faFilter, faFlag, faFolderOpen, faFont, faGift, faGlobeAfrica, faHdd, faHeadset, faHistory, faInbox, faKey, faLaptop, faLeaf, faLightbulb, faLink, faLock, faLockOpen, faMagic, faMagnifyingGlass, faMapMarkerAlt, faMedal, faMobileAlt, faMoneyBill, faMoneyCheckAlt, faPaintBrush, faPencilAlt, faPercent, faPhoneAlt, faPlay, faPlug, faPlus, faPoll, faPowerOff, faPrint, faQuestion, faReceipt, faRecycle, faRedo, faRocket, faRss, faSave, faSearch, faShare, faShieldAlt, faShoppingBag, faSign, faSlidersH, faSmile, faSort, faSpinner, faStarHalf, faSync, faTabletAlt, faThumbsDown, faTrash, faTv, faUpload, faUserCircle, faVolumeUp, faWifi, faWindowClose } from '@fortawesome/free-solid-svg-icons';

const iconMapping = {
    faMapLocation, faStore, faHome, faHouse, faCalendar, faCalendarDays, faUserGroup, faCrown, faRankingStar, faImages, faChalkboardUser, faUser, faUsers, faBell, faCog, faEnvelope, faChartLine, faBook, faCamera, faCogs, faFile, faFolder, faGlobe, faHeart, faImage, faMap, faPaperclip, faPhone, faPlane, faShoppingCart, faSignInAlt, faSignOutAlt, faStar, faTags, faThumbsUp, faTools, faTrophy, faWrench, faBookmark, faBriefcase, faBullhorn, faChartPie, faClipboard, faComment, faCompass, faCopy, faCreditCard, faDatabase, faDollarSign, faDownload, faExclamationCircle, faExternalLinkAlt, faFileAlt, faFileArchive, faFileAudio, faFileCode, faFileExcel, faFileImage, faFilePdf, faFilePowerpoint, faFileVideo, faFileWord, faFilter, faFlag, faFolderOpen, faFont, faGift, faGlobeAfrica, faHdd, faHeadset, faHistory, faInbox, faKey, faLaptop, faLeaf, faLightbulb, faLink, faLock, faLockOpen, faMagic, faMagnifyingGlass, faMapMarkerAlt, faMedal, faMobileAlt, faMoneyBill, faMoneyCheckAlt, faPaintBrush, faPencilAlt, faPercent, faPhoneAlt, faPlay, faPlug, faPlus, faPoll, faPowerOff, faPrint, faQuestion, faReceipt, faRecycle, faRedo, faRocket, faRss, faSave, faSearch, faShare, faShieldAlt, faShoppingBag, faSign, faSlidersH, faSmile, faSort, faSpinner, faStarHalf, faSync, faTabletAlt, faThumbsDown, faTrash, faTv, faUpload, faUserCircle, faVolumeUp, faWifi, faWindowClose,
    faFacebook,
    faWhatsapp,
    faInstagram,
    faLinkedin,
    faYoutube,
    faTwitter
};

const getIcon = (iconName) => iconMapping[iconName] || iconMapping.faQuestionCircle;

const Sections = [
    {
        href: "/",
        icon: "faHouse",
        title: "Página inicial",
        displayOnFooter: true,
    },
    {
        href: "/agenda",
        icon: "faCalendarDays",
        title: "Agenda",
        displayOnFooter: true,
    },
    {
        href: "/expositores",
        icon: "faStore",
        title: "Expositores",
        displayOnFooter: false,
    },
    // {
    //     href: "/feed",
    //     icon: faShareNodes,
    //     title: "Feed",
    //     displayOnFooter: true,
    // },
    {
        href: "/contatos",
        icon: "faUserGroup",
        title: "Networking",
        displayOnFooter: true,
    },
    {
        href: "/palestrantes",
        icon: "faChalkboardUser",
        title: "Palestrantes",
        displayOnFooter: false,
    },
    {
        href: "/gamificacao",
        icon: "faRankingStar",
        title: "Gamificação",
        displayOnFooter: false,
    },
    {
        href: "/pontos-de-interesse",
        icon: "faMapLocation",
        title: "Pontos de interesse",
        displayOnFooter: false,
    },
    {
        href: "/patrocinadores",
        icon: "faCrown",
        title: "Patrocinadores",
        displayOnFooter: false,
    },
    {
        href: "/galeria",
        icon: "faImages",
        title: "Galeria",
        displayOnFooter: false,
    }
];

const Sidebar = () => {
    const { dataApplication } = useGlobalState()
    const { isOpen, setIsOpen } = useSidebar();

    const innerRef = useOuterClick(() => {
        if (window.innerWidth <= 769 && isOpen) {
            setIsOpen(false)
        }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkWidthAndUpdateSidebarState = () => {
        if (window.innerWidth <= 769) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", checkWidthAndUpdateSidebarState);
        return () => {
            window.removeEventListener("resize", checkWidthAndUpdateSidebarState);
        };
    }, [checkWidthAndUpdateSidebarState]);

    let combinedSections = [...Sections];
    if (dataApplication.customizacao.sections && dataApplication.customizacao.sections.length > 0) {
        combinedSections = [...Sections, ...dataApplication.customizacao.sections];
    }
    return (
        <section className={`sidebar ${isOpen ? "collapsed" : ""}`} ref={innerRef}>
            <div className="sidebar-content">
                <PerfectScrollbar>
                    <p className="fw-bold text-center">Menu</p>
                    <ul className="sidebar-nav">
                        {combinedSections.map((item, i) => (
                            <SidebarNavItem key={'navItem' + i} item={item} />
                        ))}
                    </ul>
                </PerfectScrollbar>
            </div>
        </section>
    );
}

const SidebarNavItem = ({ item }) => {
    const location = useLocation();
    const { isOpen, setIsOpen } = useSidebar();
    const [open, setOpen] = useState();
    const icon = getIcon(item.icon);
    const navigate = useNavigate();

    const debouncedNavigate = useDebounce((path, state) => {
        navigate(path + location.search, { state: { props: state } });
    }, 250);

    const handleClick = (e, path, state) => {
        e.preventDefault();
        debouncedNavigate(path, state);
    };

    const handleToggle = () => {
        setOpen((state) => !state);
    };

    const handleSidebar = () => {
        if (isOpen) {
            if (window.innerWidth <= 769) {
                setIsOpen(!isOpen);
            }
        }
    };

    if (item.children) {
        return (
            <li className={`sidebar-item ${open ? "active" : ""}`}>
                <a className={`${open ? "" : "collapsed"} sidebar-link`} data-bs-toggle="collapse" aria-expanded={open ? "true" : "false"} onClick={handleToggle}>
                    <FontAwesomeIcon icon={item.icon} className="me-2" size="lg" />
                    <span>{item.title}</span>
                    <motion.div
                        animate={{ rotate: open ? 180 : 0 }}
                        transition={{ duration: 0.2 }}
                        className="ms-2 float-end"
                    >
                        <FontAwesomeIcon icon={faChevronDown} size={'sm'} />
                    </motion.div>
                </a>
                <Collapse in={open}>
                    <ul className="sidebar-dropdown list-unstyled">
                        {item.children.map((props, ip) => (
                            <li key={'sidebar-item-children' + ip}>
                                <a
                                    onClick={(e) => handleClick(e, props.item.href)}
                                    className={`${location.pathname === props.item.href ? 'active' : ''} sidebar-link`}
                                >
                                    <span>{props.title}</span>
                                </a>
                            </li>
                        ))
                        }
                    </ul>
                </Collapse>
            </li>
        );
    }

    return (
        <li className="sidebar-item">
            {item.external_link ? (
                <a className="sidebar-link" target="_blank" rel="noopener noreferrer" href={item.href}>
                    <span>
                        <FontAwesomeIcon icon={icon} className="me-2" size="lg" />
                        {item.title}
                    </span>
                </a>
            ) : (
                <a
                    className={`${location.pathname === item.href ? 'active' : ''} ${item.displayOnFooter ? " displayOnFooter" : ""} sidebar-link`}
                    onClick={(e) => {
                        e.preventDefault();
                        debouncedNavigate(item.href, item);
                        handleSidebar()
                    }}>
                    <span>
                        <FontAwesomeIcon icon={icon} className="me-2" size="lg" />
                        {item.title}
                    </span>
                </a>
            )}
        </li>
    );
}

export default Sidebar