import { useState } from 'react';
import { AnimatePresence, motion } from "framer-motion"
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Button, Row, Col, Image, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrophy,
    faAward
} from "@fortawesome/free-solid-svg-icons";
import { useAppState } from '../hooks/useApp';
import { useGlobalState } from '../hooks/useInitialization';
import { friendRequest, blockRequest } from "../pages/protected/Networking.js"

export const NetworkingConfirmationModal = ({ pageData, setPageData, pageDataComplete, setPageDataComplete, pageDataItem, setPageDataItem, setTotalContacts }) => {
    const { socket, modalConfirmation, setModalConfirmation, confirmationProps, setConfirmationProps } = useAppState()

    function handleClose() {
        setModalConfirmation(false);
        setConfirmationProps({});
    }

    const confirmationHandler = (e) => {
        switch (confirmationProps.type) {
            case 'remove':
                friendRequest(e, confirmationProps.status, confirmationProps.user_id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem)
                setConfirmationProps({})
                setModalConfirmation(false)
                break;
            case 'block':
                blockRequest(e, confirmationProps.status, confirmationProps.user_id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem)
                setConfirmationProps({})
                setModalConfirmation(false)
                break;
            case 'unblock':
                blockRequest(e, confirmationProps.status, confirmationProps.user_id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem)
                setConfirmationProps({})
                setModalConfirmation(false)
                break;
            default:
                break;
        }
    }

    return (
        <Modal
            show={modalConfirmation}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            animation={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>{confirmationProps.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{confirmationProps.description}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Voltar
                </Button>
                <Button variant="primary" onClick={confirmationHandler}>Confirmar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export const QRCodeModal = ({ pageDataItem, showModal, setShowModal }) => {
    const { dataApplication } = useGlobalState()
    const [name, setName] = useState()
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);

    function handleClose() {
        setShowModal(false);
    }

    if (pageDataItem) {
        switch (pageDataItem.type) {
            case 'interest_point':
                return (
                    <Modal
                        show={showModal}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        centered
                        fullscreen={'sm-down'}
                        animation={true}
                        className="pointsOfInterestCardQr"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Novo ponto de interesse</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="qrcodeItemBody text-center">
                            <Row className="justify-content-center">
                                <div className="qrcodeItemCard">
                                    <AnimatePresence>
                                        <motion.div
                                            className={`imageContainer mb-3 ${isLoaded ? '' : 'not-collected'}`}
                                            initial={{ filter: 'grayscale(100%) blur(2px)' }}
                                            animate={isLoaded ? { filter: 'grayscale(0%) blur(0px)' } : {}}
                                            transition={{ delay: 0.5, duration: 1.5 }}>
                                            <img
                                                src={`${dataApplication.customizacao.server.imageServer}/plataforma-eventos/${dataApplication.event_id}/${pageDataItem.message.json.image}`}
                                                alt="points of interests images"
                                                onLoad={() => setIsLoaded(true)}
                                            />
                                        </motion.div>
                                    </AnimatePresence>
                                    <div className="pointsOfInterestDetails">
                                        <h6 className="fw-bold mb-2">
                                            {pageDataItem.message.json.title}
                                        </h6>
                                        <div>
                                            <Badge bg="outline-secondary"> {pageDataItem.message.value}</Badge>
                                        </div>
                                    </div>
                                </div>
                                <Col sm={12} className="px-4 my-auto my-md-4">
                                    <p>Ponto de interesse <b>{pageDataItem.message.json.title}</b> coletado.<br></br>Você aumentou sua pontuação!</p>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal >
                )
            case 'contact':
                return (
                    <Modal
                        show={showModal}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        centered
                        fullscreen={'sm-down'}
                        animation={true}
                        className="qrcodeModal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Novo contato</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="qrcodeItemBody text-center">
                            <Row>
                                <div className="qrcodeItemCard">
                                    <Image className="perfil mb-3" src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + pageDataItem.message.image} roundedCircle ></Image>
                                    {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                        const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                        if (pageDataItem.message.json && pageDataItem.message.json[field.inputID]) {
                                            if (pageDataItem.message.json && pageDataItem.message.json[field.inputID]) {
                                                if (index === 0) {
                                                    if (!name) {
                                                        setName(pageDataItem.message.json[field.inputID].value)
                                                    }
                                                    return (
                                                        <h6 className="mb-0" key={'h5' + field.inputID}>{pageDataItem.message.json[field.inputID].value}</h6>
                                                    )
                                                } else {
                                                    return (
                                                        <p className="mt-1 mb-0 small" key={'small' + field.inputID}>{pageDataItem.message.json[field.inputID].value}</p>
                                                    )
                                                }
                                            }
                                        }
                                        return null;
                                    })}
                                    <div>
                                        <Button type="button" bg={'primary'} className="my-3 btn-sm" onClick={() => navigate(`/contatos/${pageDataItem.message.user_id}${location.search}`)} >Ver perfil</Button>
                                    </div>
                                </div>
                                <Col sm={12} className="px-4 my-auto my-md-4">
                                    <p>Você adicionou <b>{name}</b> na lista de contatos.<br></br><br></br>Agora você pode visualizar todas suas informações e interagir com ele!</p>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>Voltar</Button>
                        </Modal.Footer>
                    </Modal>
                )
            default:
                return ""
        }
    }
}