import React, { useState, useRef, useContext, useEffect } from "react"
import { useSearchParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion"
import {
    Form,
    Button
} from "react-bootstrap"
import * as Yup from "yup";
import { Formik, Field } from "formik"
import { PageLoader } from './Elements';
import NotyfContext from "../contexts/NotyfContext.js";
import { useGlobalState } from '../hooks/useInitialization.js';
import { redirectURL } from "./Functions.js"
import logoFlex from '../assets/img/logoFlex.svg'

const initialValues = {
    code01: '',
    code02: '',
    code03: '',
    code04: '',
    code05: '',
    code06: '',
};

const validationSchema = Yup.object().shape({
    code01: Yup.string().max("1", ""),
    code02: Yup.string().max("1", ""),
    code03: Yup.string().max("1", ""),
    code04: Yup.string().max("1", ""),
    code05: Yup.string().max("1", ""),
    code06: Yup.string().max("1", "")
});

const TokenScreen = ({ setToken, error }) => {
    const { setError, setUpdateDataApplication, miniLoader, setMiniLoader } = useGlobalState();
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const notyf = useContext(NotyfContext);
    const [errorCount, setErrorCount] = useState(0);

    const inputRefs = {
        code01: useRef(null),
        code02: useRef(null),
        code03: useRef(null),
        code04: useRef(null),
        code05: useRef(null),
        code06: useRef(null),
    };

    const handleInputChange = (fieldName, value, setFieldValue) => {
        setFieldValue(fieldName, value.charAt(0))
        // Check if pasted content is exactly 6 characters

        if (value.length === 6) {
            const inputFieldNames = Object.keys(inputRefs);

            // Fill each input field with a character from the pasted value
            for (let i = 0; i < inputFieldNames.length; i++) {
                const inputFieldName = inputFieldNames[i];
                const inputValue = value.charAt(i);
                // Update the Formik state first
                setFieldValue(inputFieldName, inputValue, false);

                // Update the input field value and move to the next field
                if (inputValue) {
                    inputRefs[inputFieldName].current.value = inputValue;

                    // Move to the next input field
                    if (i < inputFieldNames.length - 1) {
                        const nextFieldName = inputFieldNames[i + 1];
                        inputRefs[nextFieldName].current.focus();
                    }
                }
            }
        } else if (value.length === 1) {
            const nextFieldName = getNextFieldName(fieldName);
            if (nextFieldName && nextFieldName !== null) {
                inputRefs[nextFieldName].current.focus();
            }
        }
    };

    const handleBackspace = (fieldName, value) => {
        if (value.length === 0) {
            const prevFieldName = getPrevFieldName(fieldName);
            if (prevFieldName) {
                inputRefs[prevFieldName].current.focus();
            }
        }
    };

    const getNextFieldName = (fieldName) => {
        const fieldNames = Object.keys(inputRefs);
        const currentIndex = fieldNames.indexOf(fieldName);
        return currentIndex < fieldNames.length - 1 ? fieldNames[currentIndex + 1] : null;
    };

    const getPrevFieldName = (fieldName) => {
        const fieldNames = Object.keys(inputRefs);
        const currentIndex = fieldNames.indexOf(fieldName);
        return currentIndex > 0 ? fieldNames[currentIndex - 1] : null;
    };

    const checkToken = (values, actions) => {
        const token = Object.values(values).join('');
        setMiniLoader(true)
        if (token.length === 6) {
            setUpdateDataApplication((prevCount) => prevCount + 1)
            setToken(token)
            setError(false)
            setSearchParams(`?token=${token}`)
        } else {
            setTimeout(() => {
                setMiniLoader(false)
                setError("Código de acesso inválido")
                setErrorCount((prevCount) => prevCount + 1)
                actions.resetForm();
                actions.setSubmitting(false)
            }, 1500);
        }
    };

    useEffect(() => {
        if (error && error !== true) {
            notyf.open({
                type: "danger",
                message: error,
                ripple: true,
                dismissible: true,
                duration: 4000,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, errorCount])

    return (
        <div className="App-start-box text-center">
            <div className="pointer" onClick={(() => redirectURL(true, 'https://flexinterativa.com.br'))}>
                <img src={logoFlex} alt="Logo" className="logo"></img>
            </div>
            <hr></hr>
            <AnimatePresence mode="wait" initial={false}>
                {!miniLoader ? (
                    <motion.div key={'fieldsInputToken'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={checkToken}
                        >
                            {({ handleSubmit, values, handleChange, setFieldValue, isSubmitting, errors, dirty }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <label className="mb-2">Código de acesso</label>
                                    <div className="row justify-content-center">
                                        {Object.keys(initialValues).map((fieldName) => (
                                            <Field key={fieldName} name={fieldName}>
                                                {({ field, meta }) => (
                                                    <input
                                                        {...field}
                                                        type="text"
                                                        className={`tokenButton form-control mb-4`}
                                                        ref={inputRefs[fieldName]}
                                                        onChange={(e) => {
                                                            handleInputChange(fieldName, e.target.value, setFieldValue);
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Backspace') {
                                                                handleBackspace(fieldName, field.value);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        ))}
                                    </div>
                                    <div className="text-center">
                                        <Button variant="primary" className="btn mx-0 btn" type="submit" disabled={isSubmitting} >
                                            Entrar
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </motion.div>
                ) : (
                    <motion.div key={'fieldsInputTokenPageLoader'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                        <div>
                            <p>Procurando evento...</p>
                            <PageLoader color={"#fff"} width="50"></PageLoader>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default TokenScreen